<template>
  <div class="text-center">
    <div class="row pt-1">
      <div class="col-lg-12">
        <h3 class="mb-2 text-center">Accident Incident Company Instructions</h3>
      </div>
      <div class="col-lg-5">
        <form class="form-inline small" @submit.prevent="searchAiMasterCompanyInstruction">
          <input class="form-control form-control-sm w-75" type="search"
                 placeholder="Search Instruction Description ..."
                 aria-label="Search Instruction Description ..." v-model="filters.search">
          <button class="e-btn e-btn-outlined-green e-text-hover-white-light-1" type="submit"
                  style="margin-left: -29px; padding-top: 0.35rem; padding-bottom: 0.35rem;">
            <font-awesome-icon class="e-text-green" icon="search"/>
          </button>
        </form>
      </div>
<!--      todo Add dynamic dropdown of company instructions > only on filter add the CANCELLED status-->
      <div class="col-lg-3">
        <label for="statusDrpdwn" class="pr-2">By Status</label>
        <select class="form-control w-auto form-control-sm d-inline-block"
                @change="filterByStatus"
                v-model="filters.statusId"
                id="statusDrpdwn"
        >
          <option :value="null">All</option>
          <option :value="1">Pending</option>
          <option :value="2">In Progress</option>
          <option :value="3">Done</option>
          <option :value="4">Cancelled</option>
        </select>
      </div>
      <div class="col-lg-4 align-items-end">
        <nav aria-label="...">
          <ul class="pagination justify-content-end text-smallest mb-2 pb-0">
            <li class="page-item" :class="{ 'disabled': pagination.currentPage === 1}">
              <button class="page-link" @click="setPage(1)">First</button>
            </li>

            <li v-for="page in paginationPages" :key="page" class="page-item"
                :class="{ 'disabled': page === '...', 'active': page === pagination.currentPage }">
              <button class="page-link" @click="setPage(page)">{{ page }}</button>
            </li>

            <li class="page-item" :class="{ 'disabled': pagination.currentPage === pagination.lastPage}">
              <button class="page-link" @click="setPage(pagination.lastPage)">Last</button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <div class="row no-gutters" style="height: 80vh; overflow-y: auto">
      <div class="col-sm-12">
        <table class="small e-tbl">
          <thead class="bg-white" style="position: sticky; top: 0; z-index: 2">
          <tr>
            <th class="align-middle" style="width: 12em">REPORT NO.</th>
            <th class="align-middle" style="width: 12em">INSTRUCTION TYPE</th>
            <th class="align-middle">INSTRUCTION TITLE</th>
            <th class="align-middle" style="width: 15em">DATE SENT</th>
            <th class="align-middle" style="width: 17em">SENT BY</th>
            <th class="align-middle" style="width: 10em" >STATUS</th>
            <th class="align-middle" style="width: 7em" >ACTION</th>
          </tr>
          </thead>
          <tbody>
          <tr v-if="loading">
            <td colspan="100%">
              <loading></loading>
            </td>
          </tr>
          <template v-else>
            <tr v-for="vesselInstruction in aiVesselCompInstructions" :key="vesselInstruction.air_ci_vessel_id">
              <td class="align-middle">{{vesselInstruction.report_code}}</td>
              <td class="align-middle">{{vesselInstruction.ai_ci_type}}</td>
              <td class="align-middle">{{vesselInstruction.ai_ci_title}}</td>
              <td class="align-middle">{{vesselInstruction.sent_at  | moment('D MMMM YYYY, h:mm:ss A')}}</td>
              <td class="align-middle text-capitalize">{{vesselInstruction.sent_by}}</td>
              <td class="align-middle text-uppercase text-white" :class="{
                'bg-ff0000': vesselInstruction.air_ci_status_id == null || vesselInstruction.air_ci_status_id==1,
                'bg-bc8526': vesselInstruction.air_ci_status_id == null || vesselInstruction.air_ci_status_id==2,
                'bg-04a44c': vesselInstruction.air_ci_status_id == null || vesselInstruction.air_ci_status_id==3,
                'text-dark': vesselInstruction.air_ci_status_id == null || vesselInstruction.air_ci_status_id==4,
              }">
                {{vesselInstruction.air_ci_status.name ? vesselInstruction.air_ci_status.name : ""}}
              </td>
              <td class="align-middle">
                <button class="e-btn e-btn-blue e-btn-sm e-text-white my-1" data-toggle="modal"
                        data-target="#addMasterComplianceInCompInstruction"
                        @click="showCompanyInstruction(vesselInstruction)">
                  <font-awesome-icon icon="eye" />
                  View
                </button>
              </td>
            </tr>
          </template>
          </tbody>
        </table>
      </div>
    </div>
    <!-- Modal -->
    <div class="modal fade" id="addMasterComplianceInCompInstruction" tabindex="-1" role="dialog" aria-labelledby="addMasterComplianceInCompInstructionTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <div class="row px-2 align-items-center">
              <template v-if="!vesselInstruction.report_confidential">
                <router-link  id="exampleModalLongTitle" class="font-weight-normal modal-title pb-0 mr-0 col-10 text-left"
                              :to="{name:'AccidentIncidentReport',params:{id:vesselInstruction.accident_incident_id}}"
                              target="_blank" style="font-size: 15px;"> <span class="p e-text-black" style="font-size: 15px;">REPORT NO.</span> {{ vesselInstruction.report_code }} - {{vesselInstruction.report_title}}</router-link>
              </template>

              <template v-else>
                <div class="col-10">
                  <p class="font-weight-normal modal-title pb-0 mr-0 text-left text-uppercase" style="font-size: 15px;"> REPORT NO. {{ vesselInstruction.report_code }} - {{vesselInstruction.report_title}} </p>
                </div>
              </template>

              <div class="btn-group col-2" role="group" aria-label="Basic example">
                <button type="button" class="bg-white font-weight-bold border-0 pr-3" disabled>Status: </button>
                <button type="button" class="e-btn px-3 e-btn-outlined-blue"
                        disabled
                        v-if="vesselInstruction.air_ci_status_id == 4 || vesselInstruction.canceled_at">
                  CANCELED
                </button>
                <button type="button" class="e-btn px-3 e-btn-blue"
                        disabled
                        v-else>
                  ONGOING
                </button>
              </div>
              </div>

            <button type="button" class="close ml-0" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body pb-0" style="max-height: 80vh; overflow-y: auto">
            <div class="row mb-2 text-left">
              <div class="col-lg-2 font-weight-bold">
                Instruction:
              </div>
              <div class="col-lg-10">
                {{ vesselInstruction.ai_ci_title}}
              </div>
            </div>
            <div class="row mb-2 text-left">
              <div class="col-lg-2 font-weight-bold">
                Instruction Detail:
              </div>
              <div class="col-lg-10">
                <div class="pre-wrap-txt textarea-display-card" v-html="vesselInstruction.ai_ci_description"></div>
              </div>
            </div>
            <div class="row mb-2 text-left">
              <div class="col-lg-2 font-weight-bold">
                Instruction Type:
              </div>
              <div class="col-lg-10">
                {{vesselInstruction.ai_ci_type}}
              </div>
            </div>
            <form @submit.prevent="submitVesselResponse('companyInstructionVesselAttachmentInput',vesselInstruction.air_ci_vessel_id)" class="my-3">
              <div class="row mb-2 text-left">
                <div class="col-lg-2 font-weight-bold">
                  Job Done:
                </div>
                <div class="col-lg-10">
                  <vue-editor :editor-toolbar="toolbar" v-model="vesselInstruction.response_detail"
                              :class="vesselInstruction.canceled_at ? 'bg-light' : ''"
                              :disabled="vesselInstruction.canceled_at != null"

                  ></vue-editor>
                </div>
              </div>
              <div class="row mb-2 text-left">
                <div class="col-lg-2 font-weight-bold">
                  Response Attachments:
                </div>
                <div class="col-lg-10">
                  <div class="row mb-2"
                       v-if="!(vesselInstruction.air_ci_status_id == 4 || vesselInstruction.canceled_at)">
                    <div class="col-12">
                      <button class="e-btn e-btn-green" v-if="!addAttachments" @click="addAttachments = true">Add Attachment</button>
                      <div class="form-group" v-else>
                        <input type="file" class="form-control-file" id="companyInstructionVesselAttachmentInput"
                               accept="image/*,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.xlsx,.xls,.csv"
                               multiple required>
                        <button class="e-btn e-btn-outlined-red float-right" @click="addAttachments = false">Cancel Attachments</button>
                      </div>
                      <br />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-2" v-for="file in instructionFiles">
                      <div id="attachment-card" class="card py-1" :title="file.attachment_name">
                        <div class="dropdown dropright mb-2">
                          <div
                            data-toggle="dropdown"
                            id="attachmentDropdown"
                            class="text-center"
                            aria-haspopup="true" style="cursor:pointer"
                          >
                            <div class="card-img-top text-center">
                              <img :src="file.thumbnail" alt="Attachment Thumbnail" style="width: 50px" />
                            </div>
                            <small id="attachment-name">{{file.attachment_name}}</small>
                            <div aria-labelledby="attachmentDropdown" class="dropdown-menu">
                              <button class="dropdown-item text-secondary font-weight-bolder"
                                      type="button"
                                      v-if="file.extension === 'pdf'"
                                      @click="viewFile(file.id)"
                              >
                                <font-awesome-icon class="ml-2 mr-1 text-primary" icon="eye"/>
                                View Attachment
                              </button>
                              <button class="dropdown-item text-secondary font-weight-bolder"
                                      @click="downloadFile(file.id)"
                                      type="button"
                              >
                                <font-awesome-icon class="ml-2 mr-1 text-primary" icon="download"/>
                                Download Attachment
                              </button>
                              <button class="dropdown-item  text-danger font-weight-bolder"
                                      v-if="!(vesselInstruction.air_ci_status_id == 4 || vesselInstruction.canceled_at)"
                                      @click="deleteFile(file.id)"
                                      type="button"
                              >
                                <font-awesome-icon class="ml-2 mr-1 force-danger-all" icon="trash"/>
                                Delete Attachment
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row text-left">
                <div class="col-lg-2 font-weight-bold">Job Completion:</div>
                <div class="col-lg-10">
                  <span v-if="vesselInstruction.canceled_at">
                    <select class="form-control w-auto form-control-sm d-inline-block  mr-5" disabled>
                      <option :value="4">CANCELED</option>
                    </select>
                  </span>
                  <span v-else>
                    <select class="form-control w-auto form-control-sm d-inline-block  mr-5"
                            v-model="vesselInstruction.air_ci_status_id">
                      <option :value="1">Pending</option>
                      <option :value="2">In Progress</option>
                      <option :value="3">Done</option>
                    </select>
                  </span>
                </div>
              </div>
              <div class="row" v-if="!vesselInstruction.canceled_at">
                <div class="col-12 text-right">
                  <button type="submit" class="e-btn e-btn-green e-btn-md px-5">SUBMIT</button>
                </div>
              </div>
            </form>
          </div>
<!--          <div class="modal-footer">-->
<!--            <button type="button" class="e-btn e-btn-green e-btn-md px-5" data-dismiss="modal">SUBMIT</button>-->
<!--          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import companyInstruction from "../../accident-incident/AirCompanyInstruction.vue";
import {AlertService} from "@/services/AlertService";
import {ReportService} from "@/services/ReportService";
import {FileService} from "@/services/FileService";
import AppLoading from "@/components/elements/AppLoading.vue";
import {PaginationMixin} from "@/mixins/PaginationMixin";

export default {
  name: "MasterAirCompanyInstruction",
  data(){
    return {
      loading: false,
      filters: {
        page: 1,
        search: '',
        statusId: null
      },
      addAttachments: false,
      ciVesselStatus: null,
      vesselInstruction: {},
      instructionFiles: {},
      testData: "Please check available spare gasket for safety valve of aux. boiler and report to your Tech S/I by e-mail with following items\n" +
        "\n" +
        "  a) The gasket shall be the original one provided by the maker.\n" +
        "\n" +
        "  b) At least one pc shall be onboard\n" +
        "\n" +
        "Thank you.",
      toolbar:[
        ['bold','italic','strike','underline'],
        [{'color':["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff",
            "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff",
            "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff",
            "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2",
            "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466"]}]
      ]
    }
  },
  components: {
    'loading': AppLoading
  },
  mixins: [PaginationMixin],
  methods: {
    ...mapActions(['getAiVesselCompInstructions']),
    setPage(page) {
      this.pagination.currentPage = page
      this.filters.page = page
      this.getAiVesselCompInstructions(this.filters)
    },
    async showCompanyInstruction(instruction){
      this.vesselInstruction = Object.assign(instruction)
      this.instructionFiles = await ReportService.getCiVesselFiles(instruction.air_ci_vessel_id)
    },
    async submitVesselResponse(inputID,civId){
      if (await AlertService.confirmUpdateAlert()){
        await ReportService.sendVesselResponse(civId,this.vesselInstruction)

        let inputFile = document.getElementById(inputID);
        let formData = new FormData();
        if (inputFile) {
          let tempInputFile = inputFile.files
          for (let i = 0; i < tempInputFile.length; i++ ){
            formData.append('attachments[]', tempInputFile[i])
            formData.append('air_ci_vessel_id', civId);
          }
          await ReportService.addCiVesselFiles(formData, civId)
          document.getElementById(inputID).value = null
        }
        AlertService.successAlert('Sending Response to Company Instruction Success', 'UPDATE')
        await this.getAiVesselCompInstructions(this.ciVesselStatus)
        this.addAttachments= false
        this.instructionFiles = await ReportService.getCiVesselFiles(civId)
      }
    },
    async viewFile(attachmentId){
      swal.fire({
        title:`Viewing file... <br> Please Wait`
      })
      swal.showLoading();
      let response=await ReportService.viewAirCiVesselFile(attachmentId);
      if(response?.file !== null){
        swal.close();
        let blob=FileService.base64FileToBlob(response.file,response.mimeType);
        const link = document.createElement('a');
        link.setAttribute('href', URL.createObjectURL(blob));
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
      }
    },
    async downloadFile(attachmentId){
      swal.fire({
        title:`Downloading file... <br> Please Wait`
      })
      swal.showLoading();
      let response=await ReportService.viewAirCiVesselFile(attachmentId);
      if(response?.file !== null){
        swal.close();
        let blob=FileService.base64FileToBlob(response.file,response.mimeType);
        const link = document.createElement('a');
        link.setAttribute('href', URL.createObjectURL(blob));
        link.setAttribute('download', response.fileName.substr(0,response.fileName.lastIndexOf('.')));
        document.body.appendChild(link);
        link.click();
      }
    },
    async deleteFile(attachmentId){
      if(await AlertService.confirmDeleteAlert(null, 'Do you want to Delete this Attachment?', 'Yes, Delete Attachment!')){
        let response=await ReportService.deleteAirCiVesselFile(attachmentId);
        if(response?.success){
          AlertService.successAlert('Company Instruction Vessel Attachment has been Deleted Successfully!','Delete Company Instruction Attachment')
          this.instructionFiles=this.instructionFiles.filter(file=>file.id!==attachmentId);
        }
      }

    },
    async searchAiMasterCompanyInstruction(){
      this.loading = true
      this.filters.page = 1
      await this.getAiVesselCompInstructions(this.filters)
      this.loading = false
    },
    async filterByStatus(){
      this.loading = true
      this.filters.page = 1
      await this.getAiVesselCompInstructions(this.filters)
      this.loading = false
    }
  },
  async mounted() {
    this.loading = true
    await this.setPage()
    this.loading = false
  },
  computed: {
    ...mapGetters(['aiVesselCompInstructions'])
  }
}
</script>

<style scoped>
::v-deep .pre-wrap-txt.textarea-display-card p {
  margin-bottom: 0 !important;
}

#attachment-card:hover > .dropdown small {
  color: white;
}

#attachment-card:hover {
  background: rgba(255, 0, 0, 0.7);
  transition: 0.5s;
}

#attachment-name{
  width: 100%;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#exampleModalLongTitle:hover {
  text-decoration: none;
}
</style>
